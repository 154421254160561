import './LoadingSpinner.css'
import React from 'react'

const LoadingSpinner = () => {
	return (
		<div className='spinner-container'>
			<div className='spinner'></div>
		</div>
	)
}

export default LoadingSpinner
